import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ResourceList from '../components/ResourceList';
import { KnowledgeHubHeader } from '../components/KnowledgeHubHeader';
import './blog.scss';

export default class Publications extends React.Component {
  constructor(props) {
    super(props);
    const { data: { resourcesOnPage: { edges: resources } } } = this.props;
    this.state = {
      activeResources: resources
    };
  }

  searchItems = (searchTerm) => {
    const { data: { resourcesOnPage: { edges: resources } } } = this.props;
    const activeResources = resources.filter(resource => resource.node.title.toLowerCase().includes(searchTerm));
    return this.setState({
      activeResources
    });
  }

  render() {
    const { data, pageContext, location } = this.props;
    const { activeResources } = this.state;
    const { site, resourceTypes } = data;
    const { title: siteTitle, publicationsSlug, siteUrl } = site.siteMetadata;
    const pathPrefix = `/${publicationsSlug}`;
    return (
      <Layout className="publications-page-template">
        <SEO title={`Publications | ${siteTitle}`} />
        <KnowledgeHubHeader
          location={location}
          activeTab="publications"
          searchItems={this.searchItems}
        />
        <div className="wrap">
          <ResourceList
            title="Publications"
            siteUrl={siteUrl}
            resources={activeResources}
            pageContext={pageContext}
            pathPrefix={pathPrefix}
            resourceTypes={resourceTypes.edges}
          />
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query PublicationsQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        publicationsSlug
        siteUrl
      }
    }
    allResources: allWordpressWpResource {
      edges {
        node {
          ...ResourceListFields
        }
      }
    }
    resourcesOnPage: allWordpressWpResource(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ResourceListFields
        }
      }
    }
    resourceTypes: allWordpressWpResourceType(filter: { count: { gt: 0 } }) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
  }
`
